var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{ref:"slider",staticClass:"range-slider"},[_c('input',{attrs:{"type":"range","min":_vm.min,"max":_vm.max},domProps:{"value":_vm.minValue},on:{"input":function (ref) {
	var target = ref.target;

	return (_vm.updateRangeValue(parseFloat(target.value), false));
}}}),_vm._v(" "),_c('input',{attrs:{"type":"range","min":_vm.min,"max":_vm.max},domProps:{"value":_vm.maxValue},on:{"input":function (ref) {
	var target = ref.target;

	return (_vm.updateRangeValue(parseFloat(target.value), true));
}}})]),_vm._v(" "),_c('div',{staticClass:"minmax-inputs"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.minValue),expression:"minValue"}],attrs:{"type":"number","min":_vm.min,"max":_vm.max},domProps:{"value":(_vm.minValue)},on:{"input":[function($event){if($event.target.composing){ return; }_vm.minValue=$event.target.value},function (ref) {
	var target = ref.target;

	return (_vm.updateRangeValue(parseFloat(target.value), false));
}]}}),_vm._v(" "),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.maxValue),expression:"maxValue"}],attrs:{"type":"number","min":_vm.min,"max":_vm.max},domProps:{"value":(_vm.maxValue)},on:{"input":[function($event){if($event.target.composing){ return; }_vm.maxValue=$event.target.value},function (ref) {
	var target = ref.target;

	return (_vm.updateRangeValue(parseFloat(target.value), true));
}]}})])])}
var staticRenderFns = []

export { render, staticRenderFns }